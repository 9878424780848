.BaseTable a {
  text-decoration: none;
}
.BaseTable__row-cell {
  color: rgba(0, 0, 0, 0.87);
  padding: 12px 16px !important;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1rem;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.notapplicable {
      color: #c4c4c4;
    }
  }
}

.BaseTable__row:hover,
.BaseTable__row--hovered {
  background: rgba(242, 242, 242, 0.5) !important;
}

.BaseTable__header-row {
  background: #fff !important;

  .BaseTable__header-cell {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 14px 16px;
    white-space: nowrap;
    font-weight: bold;
    align-items: center;
    user-select: none;
    box-sizing: border-box;

    &--sortable:hover {
      background: unset !important;
    }
  }
}

.BaseTable__header-row .BaseTable__header-cell {
  white-space: pre-wrap;
  padding: 0;
}

.BaseTable__table-main .BaseTable__header-cell:last-child,
.BaseTable__table-main .BaseTable__row-cell:last-child {
  padding: 0;
}
