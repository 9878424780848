$mobile-threshold: 1024px; //can't import it from JS, but is the same as in src/hooks/useIsMobile.tsx
@import url('https://fonts.googleapis.com/css?family=Archivo:400,500,600,700&display=swap');

html,
body,
#root {
  min-height: 100%;
  width: 100%;
  margin: 0;
  background-image: none;
  overscroll-behavior: none;
}

html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'Archivo', 'Arial', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dialogScrollbar {
  &::-webkit-scrollbar-button {
    width: 5px;
    height: 5px;
  }
}

.hustro-multiselect:focus-within .hustro-controller {
  opacity: 1;
}

.apexcharts-toolbar {
  flex-direction: row-reverse;

  & .apexcharts-element-hidden {
    display: none;
  }

  $toolbar-manipulation-icons: '.apexcharts-zoomin-icon, .apexcharts-zoomout-icon, .apexcharts-zoom-icon, .apexcharts-pan-icon, .apexcharts-reset-icon,';

  & #{$toolbar-manipulation-icons},
  .apexcharts-menu-icon {
    width: 24px;
    height: 24px;
    transform: scale(1);
    margin: 0 0 0 8px;
    @media screen and (max-width: $mobile-threshold) {
      margin: 0;
    }
    & img {
      opacity: 0.5;
    }
  }
  & #{$toolbar-manipulation-icons} {
    @media (hover: none) and (pointer: coarse) {
      display: none;
    }
  }
  & .apexcharts-zoomin-icon {
    order: 6;
  }
  & .apexcharts-zoomout-icon {
    order: 5;
  }
  & .apexcharts-zoom-icon {
    order: 4;
  }
  & .apexcharts-pan-icon {
    order: 3;
  }
  & .apexcharts-reset-icon {
    order: 2;
  }
  & .apexcharts-menu-icon {
    order: 1;
  }

  @media screen and (max-width: $mobile-threshold) {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  & .apexcharts-selected img {
    opacity: 1;
  }
}

:-webkit-any-link {
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

:-moz-any-link {
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

:any-link {
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

.detected-resolved-xaxis-labels {
  color: #979797;
  fill: #979797;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    height: 0;
    opacity: 0;
  }

  1% {
    display: block;
    height: 0;
    opacity: 0;
  }

  50% {
    display: block;
    height: auto;
    opacity: 0;
  }

  100% {
    display: block;
    height: auto;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    height: 0;
    opacity: 0;
  }

  1% {
    display: block;
    height: 0;
    opacity: 0;
  }

  50% {
    display: block;
    height: auto;
    opacity: 0;
  }

  100% {
    display: block;
    height: auto;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    height: 0;
    opacity: 0;
  }

  1% {
    display: block;
    height: 0;
    opacity: 0;
  }

  50% {
    display: block;
    height: auto;
    opacity: 0;
  }

  100% {
    display: block;
    height: auto;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    height: 0;
    opacity: 0;
  }

  1% {
    display: block;
    height: 0;
    opacity: 0;
  }

  50% {
    display: block;
    height: auto;
    opacity: 0;
  }

  100% {
    display: block;
    height: auto;
    opacity: 1;
  }
}

.MuiTooltip-popper {
  z-index: 50000 !important;
}
