// we need scss because of @keyframes not being serviced by material
.loader-wrapper {
  display: inline-block;
  animation: fadeIt 1s ease-in-out;
  transition: background-color 2s linear;
}

@keyframes fadeIt {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loaderAnimation {
  animation: rotation 0.6s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
